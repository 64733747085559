import { Component,ApplicationRef,OnInit,Renderer2  } from '@angular/core';
import { Router, NavigationEnd, Event as NavigationEvent,NavigationExtras,RouterEvent  } from '@angular/router';
import { filter } from 'rxjs/operators';
import { NavController } from '@ionic/angular';
import {Map} from 'leaflet';
import { register } from 'swiper/element/bundle';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';

import { TranslateService } from '@ngx-translate/core';
import { ApiService } from './services/api.service';
import { StorageService } from "./services/storage.service";
import { Badge } from '@capawesome/capacitor-badge';
import { MenuController } from '@ionic/angular';
import { Browser } from '@capacitor/browser';

import { AnalyticsService } from'./services/analytics.service';
import { Capacitor, Plugins } from '@capacitor/core';

import {UpdateAppPopUpComponent} from './components/update-app-pop-up/update-app-pop-up.component';



register();



import { timeStamp } from 'console';
import {
  Auth,
  getAuth,
  onAuthStateChanged ,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  AuthInstances
} from "@angular/fire/auth";
import { getFirestore, doc, setDoc,getDoc } from "@angular/fire/firestore";

import { PushNotifications, Token, PushNotificationSchema, ActionPerformed } from '@capacitor/push-notifications';
import { IonRouterOutlet, Platform,ModalController } from '@ionic/angular';
import { Optional } from '@angular/core';

import { first } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { Subscription } from 'rxjs';
import { url } from 'inspector';
import { App } from '@capacitor/app';


import { GeoService } from "./services/geo.service";


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],


})
export class AppComponent implements OnInit {
  showSplash = environment.production;
  isLoading = false;
  private db: Promise<IDBDatabase>;
  private backButtonSubscription!: Subscription;
  private previousUrl!: string;
  private currentUrl!: string;
  private otp:boolean = true;
  private chetTeam = false;
  CanEdit = false;

  constructor(private renderer: Renderer2,private modalController: ModalController,private GeoService:GeoService, private navCtrl: NavController,private auth: Auth,private analyticsService: AnalyticsService,private platform: Platform,private appRef: ApplicationRef,private translate: TranslateService,private router: Router,private StorageService:StorageService,private ApiService:ApiService,private menu: MenuController
    ,@Optional() private routerOutlet?: IonRouterOutlet

    ) {
    this.translate.setDefaultLang('en');
    this.db = this.initializeDatabase();
    this.ngOnInit();
    this.handleInputFocus();

    this.currentUrl = '';
    this.previousUrl = '';

    this.router.events
    .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.urlAfterRedirects;
      console.log('URL-this.previousUrl',this.previousUrl);
      console.log('URL-this.currentUrl',this.currentUrl);



    });


    this.analyticsService.logUserPlatform();


    this.platform.backButton.subscribeWithPriority(-1, () => {
      if (this.routerOutlet && !this.routerOutlet.canGoBack()) {
        App.exitApp();
      }
    });





  }


  handleInputFocus() {
    const inputs = document.querySelectorAll('input, textarea');

    inputs.forEach(input => {
      // Get the placeholder and provide a fallback empty string if it's null
      let placeholder = input.getAttribute('placeholder') || '';

      // Cast the element to HTMLInputElement or HTMLTextAreaElement
      const inputElement = input as HTMLInputElement | HTMLTextAreaElement;

      // Focus Event - Remove Placeholder
      this.renderer.listen(input, 'focus', () => {
        inputElement.setAttribute('placeholder', '');
      });

      // Blur Event - Restore Placeholder if empty
      this.renderer.listen(input, 'blur', () => {
        if (inputElement.value === '') {
          inputElement.setAttribute('placeholder', placeholder);
        }
      });
    });
  }


  async update_popup(dv: any, av: any, apiVersion: any,countryName:string,update_url:string): Promise<void> {
    // product not exist, show popup to add product with cancel and  add product button

    const modal: HTMLIonModalElement = await this.modalController.create({
      component: UpdateAppPopUpComponent,
      componentProps: {
        'dv': dv,
        'av': av,
        'apiVersion': apiVersion,
        'countryName': countryName,
        'update_url': update_url
      }
    });

    await modal.present();

}


  async ngOnInit() {


    if (navigator.onLine) {
      this.ApiService.getTheSavedData("products-offline").then((data: any) => {
        console.log('products-offline->getTheSavedData',data);
        if(data){
          // loop through the array and send to API
          data.data.forEach((item: any) => {
            console.log('products-offline->item',item);
            this.ApiService.saveProduct(item).then((data: any) => {
              console.log('products-offline->products',JSON.stringify(data));
            });
          });
          // clear the data
          this.ApiService.clearCache("products-offline").then((data: any) => {
            console.log('products-offline->clearCache',data);
          });
        }
      });
    }



    /*
    let apiVersion = await this.ApiService.getVersion();
    console.log('ngOnInit.getVersion',JSON.stringify(apiVersion));
    let av = apiVersion.version;
    console.log('ngOnInit.getVersion.version',av);



    const countryName = await this.GeoService.get_country();
    let update_url = '';
    if(countryName=='CN' || countryName=='cn' || countryName=='China' || countryName=='china'){

    }

    this.platform.ready().then(async () => {
      console.log('ngOnInit.platform.ready',true);
      console.log('ngOnInit.Platform:', this.platform.platforms());

      if (this.platform.is('capacitor')) {
        let deviceVersion = await this.ApiService.getAppInfo();
        console.log('ngOnInit.getAppInfo',JSON.stringify(deviceVersion));
        let dv = deviceVersion.version;
        console.log('ngOnInit.getAppInfo.version',dv);

        let apiVersion = await this.ApiService.getVersion();
        console.log('ngOnInit.getVersion',JSON.stringify(apiVersion));
        let av = apiVersion.version;
        console.log('ngOnInit.getVersion.version',av);


          update_url = apiVersion['china'];


        if(dv!==av){
          // show popup to update the app
           this.update_popup(dv,av,apiVersion,countryName,update_url);
        }
      }

      this.update_popup('2.6.5','2.6.4',{},countryName,update_url);


    });*/




    /*
  //this.currentUrl = this.router.url;
  console.log('this.router.url', this.router.url);
  this.router.events.subscribe(event => {
    if (event instanceof NavigationEnd) {
      console.log('NavigationEnd-->event', event);
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;

      console.log('URL-->this.previousUrl', this.previousUrl);
      console.log('URL-->this.currentUrl', this.currentUrl);
    }
  });*/



  let hash = window.location.hash;
  let params = new URLSearchParams(hash.substr(hash.indexOf('?')));






    console.log('URLSearchParams',params);
    console.log('URLSearchParams->back',params.has('back'));
    console.log('URLSearchParams->noSplash',params.has('noSplash'));

    // redirect to back page
    const country = await this.GeoService.get_country();


    if (params.has('noSplash')) {
      // hide the splash screen
      this.showSplash = false;
      let params = new URLSearchParams(window.location.search);
      params.delete('noSplash');
      window.location.search = params.toString();
    }

    //this.isLoading = true;
    this.router.events.pipe(
      filter((event: NavigationEvent): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {

      console.log('URL',event.url);

      if( this.is_auth_pages(event.url) ) {
        // hide menu on login and register page
        this.menu.enable(false);

      }else{
        this.menu.enable(true);
      }


    if(country=='CN' || country=='cn' || country=='China' || country=='china'){

    return ;
    }else{
      console.log('before->onAuthStateChanged');
      onAuthStateChanged(this.auth, (user) => {
      console.log('after->onAuthStateChanged');

        if (user) {
          console.log(user);
          console.log("User is logged in");



          let fields = {};

          // User is signed in

          const db = getFirestore();
          const userRef = doc(db, "users", user.uid);
          getDoc (userRef).then((doc) => {


            if (doc.exists()) {

              console.log("Document data:", doc.data());

              let d = doc.data();

              fields =  {
                fuid: user.uid,
                company: d['company'],
                country: d['country'],
                firstName: d['firstName'],
                lastName: d['lastName'],
                displayName: d['firstName'] +' '+ d['lastName'],
                CountryCode: d['CountryCode'],
                phone: d['phone'],
                email: d['email'],
                image: "",
                d_insert: d['d_insert'],
                otp:d['otp']
              };


              this.otp = d['otp'];

              this.StorageService.set('firstName', d['firstName'] );
              this.StorageService.set('lastName', d['lastName']);
              this.StorageService.set('uname', d['firstName'] + ' ' + d['lastName']);
              this.StorageService.set('country', d['country']);
              this.StorageService.set('CountryCode', d['CountryCode']);
              this.StorageService.set('company', d['company']);
              this.StorageService.set('phone', d['phone']);
              this.StorageService.set('image', d['image']);
              this.StorageService.set('user', JSON.stringify(user));
              this.StorageService.set('fuid', user.uid);
              this.StorageService.set('email', user.email);

              this.analyticsService.setUserProperty({'name':'country', 'value':d['country'] });

              const processTeamID = async () => {
                let teamID = await this.ApiService.getTeamID();
                console.log('Tabs-->onAuthStateChanged',{fuid:user.uid},teamID);
                if(!teamID || teamID=='undefined'){
                  console.log('getTeams---~teamID',{fuid:user.uid});
                    console.log('getTeams---',{fuid:user.uid});
                    await this.ApiService.getTeams({"fuid":user.uid}).then(async (teams: any) => {
                      console.log('getTeams',teams);

                      if ( teams.length==0 && user.displayName!="undefined" && user.displayName!=null && user.displayName!="" ) {
                        await this.ApiService.createTeam({fuid: user.uid,name: user.displayName+" "+this.translate.instant("Team"),status:"active", image:"",d_insert:new Date().getTime()}).then((team: any) => {
                          console.log('create new team',team);
                          this.ApiService.setTeamID(team._id);
                        });
                      }else{
                         this.ApiService.setTeamID(teams[0].id);
                      }

                    });
              }else{
                console.log('teamID',teamID);
              }

            };

            if(!event.url.includes('/otp') && !event.url.includes('/register')){
              this.initPushNotifications(user.uid);

              if(this.chetTeam==false ){
                //processTeamID();
                this.chetTeam = true;
              }
            }

            }else{
              this.otp = false;
            }

          }).catch((error) => {
            console.log("Error getting document:", error);
          });


          if(this.is_auth_pages(event.url) ) {

            /*
            console.log('auth->OTP',this.otp,event.url.includes('/otp'));
            console.log('auth->register',this.otp,event.url.includes('/register'));
            if(this.otp===false){
              this.router.navigate(['/otp'], { queryParams: fields });
            }else{
              if(!event.url.includes('/otp') && !event.url.includes('/register')){
               // this.navigateHome();
              }
            }*/



          }else{
            console.log('auth->OTP',this.otp,event.url.includes('/otp'));
            console.log('auth->register',this.otp,event.url.includes('/register'));
          }

        } else {
          // User is signed out

        }
        this.isLoading = false;
      });
    }


      //this.isLoading = false;
      console.log(event.url);
    });



    this.initializeApp();



  }




  async initializeApp() {




    this.platform.ready().then(async () => {
      // Make the callback async


      console.log('Platform is ready');

      this.backButtonSubscription = this.platform.backButton.subscribeWithPriority(0, () => {
        console.log('Back button pressed');
        console.log('Back button pressed-->currentUrl',this.currentUrl);
        console.log('Back button pressed-->previousUrl',this.previousUrl);

        if(!this.is_auth_pages(this.previousUrl) ) {
          console.log('Navigating to previous URL');
          this.router.navigate([this.previousUrl]);
        } else {
          console.log('Previous URL is an authentication page');
        }

        if(this.currentUrl=='/tabs/home' || this.currentUrl=='login' || this.currentUrl=='/' ){
          App.exitApp();
        }

      });

      console.log('Platform:', this.platform.platforms());
      if (this.platform.is('desktop')) {

      }




      if (this.platform.is('capacitor')) {

        this.ApiService.getAppInfo().then((info: any) => {
          console.log('getAppInfo',JSON.stringify(info));
          let version = info.version;

          console.log('getAppInfo.version',version);
        });

        //const result = await Badge.requestPermissions(); // Await the requestPermissions function
        //Badge.set({ count: 10 });
        //console.log('App-setAppIconBadge', '10');
      }


      setTimeout(() => {
        this.showSplash = false;
      }, 6000);

    });





    //this.CanEdit = await this.ApiService.canEdit();
  }


  switchLanguage(language: string) {
    this.translate.use(language);
  }



  initPushNotifications(uid: string = '') {
    this.platform.ready().then(async () => {
      if (this.platform.is('capacitor')) {
        // Request permission for push notifications
        const permissionStatus = await PushNotifications.requestPermissions();
        console.log('Push permission status:', permissionStatus);
        if (permissionStatus.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();

          // On success, we should be able to receive notifications
          PushNotifications.addListener('registration',
            (token: Token) => {
              console.log('Push registration success, token: ' + token.value);

              // Send the token to your backend to keep track of the device for future push notifications
              this.sendTokenToBackend(token.value,uid);
            }
          );

          // Some issue with our setup and push will not work
          PushNotifications.addListener('registrationError',
            (error: any) => {
              console.error('Error on registration: ' + JSON.stringify(error));
            }
          );

          // Show us the notification payload if the app is open on our device
          PushNotifications.addListener('pushNotificationReceived',
            (notification: PushNotificationSchema) => {
              console.log('Push received: ' + JSON.stringify(notification));
            }
          );

          // Method called when tapping on a notification
          PushNotifications.addListener('pushNotificationActionPerformed',
            (notification: ActionPerformed) => {
              console.log('Push action performed: ' + JSON.stringify(notification));
              if(notification.notification.data.action){
                if(notification.notification.data.action=='url'){
                  //window.open(notification.notification.data.url, '_blank');
                   Browser.open({ url: notification.notification.data._id });
                }else{
                this.router.navigate(['tabs/'+notification.notification.data.action+'/'+notification.notification.data._id]);
                }
              }

            }
          );
        } else {
          // Handle when permission is not granted
          console.log('Push notifications permission was denied');
        }
      }
    });
  }


  async sendTokenToBackend(token: string,uid: string) {
    // Implement the logic to send the token to your backend/server

    console.log('Sending token to backend:', {'fuid':uid,'fcm':token}, token);
    await this.ApiService.sendDatatoAPI('user_update_insert','POST', {'fuid':uid,'fcm':token}).then((data: any) => {
      console.log('user_update_insert',JSON.stringify(data));
    });

  }




  async signOut() {


    const country = await this.GeoService.get_country();
    if(country=='CN' || country=='cn' || country=='China' || country=='china'){
      this.StorageService.set('session_id_expires', '');
      this.StorageService.set('session_id', '');


    }else{
    await signOut(this.auth).then((userData) => {
      console.log(userData);
    }).catch(function (error) {
      console.log(error['code']);
    })
  }


    // Clear the local storage
    this.ApiService.setTeamID('');
    this.StorageService.set('firstName', '' );
    this.StorageService.set('lastName', '');
    this.StorageService.set('uname', '');
    this.StorageService.set('country', '');
    this.StorageService.set('CountryCode', '');
    this.StorageService.set('phone', '');
    this.StorageService.set('image', '');
    this.StorageService.set('user', '');
    this.StorageService.set('fuid', '');
    this.StorageService.set('email', '');
    this.StorageService.set('user', '');
    this.StorageService.set('fuid', '');
    this.StorageService.set('email', '');
    this.StorageService.set('teamID', '');
    this.StorageService.set('team', '');



    localStorage.clear();
    sessionStorage.clear();

    const db = await this.db;
    const tx = db.transaction('responses', 'readwrite');
    const store = tx.objectStore('responses');
    await store.clear();

    await new Promise<void>((resolve, reject) => {
      tx.oncomplete = () => resolve();
      tx.onerror = () => reject(tx.error);
    });

    this.router.navigate(['/login']);

    /*
    this.navCtrl.navigateRoot('/?noSplash=true', { animated: true, replaceUrl: true }).then(() => {
      window.location.reload();
    });
    */

    // redirect to login page
    //this.router.navigate(['/login/refresh'],{ replaceUrl: true });

    //this.navigateLogin();
    /*
    this.router.navigate(['/login']).then(() => {
      let params = new URLSearchParams(window.location.search);
      params.set('noSplash', 'true');
      let newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + params.toString();
      window.history.pushState({ path: newUrl }, '', newUrl);
      window.location.reload();
    });*/

  }


private initializeDatabase(): Promise<IDBDatabase> {
  return new Promise((resolve, reject) => {
    const openreq = indexedDB.open('apiData', 2); // Increase the version number
    openreq.onerror = () => reject(openreq.error);
    openreq.onsuccess = () => resolve(openreq.result);
    openreq.onupgradeneeded = (event) => {
      const db: IDBDatabase = (event.target as IDBOpenDBRequest).result;
      if (!db.objectStoreNames.contains('responses')) {
        db.createObjectStore('responses');
      }
    };
  });
}


navigateLogin() {


  //window.location.href = '/';
  this.navCtrl.navigateRoot('/', { animated: true, replaceUrl: true });

  /*
  let navigationExtras: NavigationExtras = {
    animated: true,
    queryParams: { 'noSplash': 'true' },
    queryParamsHandling: 'merge' // preserve current params
  };

  this.router.navigate(['/'], navigationExtras).then(() => {
    window.location.reload();
  });*/
}


navigateHome() {
  let navigationExtras: NavigationExtras = {
    queryParams: { 'noSplash': 'true' },
    queryParamsHandling: 'merge' // preserve current params
  };

  /*
  this.router.navigate(['/tabs/home'], navigationExtras).then(() => {
    window.location.reload();
  });*/
}


is_auth_pages(url: string) {
  if(url == '/' ||  url == '/?noSplash=true' ||  url == '/?noSplash=false' || url == '/login'  ) {
    return true;
  }else{
    return false;
  }
}


ngOnDestroy() {
  this.backButtonSubscription.unsubscribe();
}


menuOpened() {
  console.log('Menu is opened');
  this.analyticsService.logEvent('header', { 'screen_name':'header','user_action':'menu_opened' });
}

menuClosed() {
  console.log('Menu is closed');
  this.analyticsService.logEvent('header', { 'screen_name':'header','user_action':'menu_closed' });
}

onMenuItemClick(itemName: string) {
  this.analyticsService.logEvent('header', { 'screen_name':'header','user_action':'menu_item_clicked', 'click_text': itemName });
}


}
