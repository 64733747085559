<ion-app>
  <app-splash-screen *ngIf="showSplash"></app-splash-screen>
  <div class="loading-overlay" *ngIf="isLoading">
    <ion-spinner></ion-spinner>
    </div>



  <ion-split-pane *ngIf="!showSplash" contentId="main-content" when="false">
    <ion-menu class="menu" side="end" contentId="main-content" type="overlay" (ionDidOpen)="menuOpened()" (ionDidClose)="menuClosed()">

      <ion-content class="menu-content">


        <ion-grid class="ion-padding ion-margin-top">
          <ion-row class="ion-align-items-end">
            <ion-col></ion-col>
            <ion-col></ion-col>
            <ion-col></ion-col>
            <ion-col style="text-align: right;">
              <ion-menu-toggle side="end" style="text-align: right;">
                <ion-button  side="end" class="btn-clear" style="text-align: right;" shape="round"><img src="../assets/icons/x-blue.svg"></ion-button>
              </ion-menu-toggle>
            </ion-col>
          </ion-row>
        </ion-grid>




        <ion-list class="ion-padding" id="menu-list">


          <ion-menu-toggle auto-hide="false" >

            <ion-item routerDirection="root" [routerLink]="'/tabs/products'" lines="none" detail="false" routerLinkActive="selected" (click)="onMenuItemClick('Products')">
              <ion-label class="menu_item">{{"Products" | translate}}</ion-label>
            </ion-item>

            <ion-item routerDirection="root" [routerLink]="'/tabs/service'" lines="none" detail="false" routerLinkActive="selected" (click)="onMenuItemClick('ServiceLog')">
              <ion-label class="menu_item">{{"ServiceLog" | translate}}</ion-label>
            </ion-item>

            <ion-item routerDirection="root" [routerLink]="'/tabs/reports'" lines="none" detail="false" routerLinkActive="selected"  (click)="onMenuItemClick('Reports')">
              <ion-label class="menu_item">{{"Reports" | translate}}</ion-label>
            </ion-item>

            <ion-item routerDirection="root" [routerLink]="'/tabs/articles'" lines="none" detail="false" routerLinkActive="selected" (click)="onMenuItemClick('NewsandUpdates')">
              <ion-label class="menu_item">{{"NewsandUpdates" | translate}}</ion-label>
            </ion-item>

            <ion-item routerDirection="root" [routerLink]="'/tabs/teams'" lines="none" detail="false" routerLinkActive="selected" (click)="onMenuItemClick('Teams')">
              <ion-label class="menu_item">{{"Teams" | translate}}</ion-label>
            </ion-item>

            <ion-item routerDirection="root" [routerLink]="'/tabs/projects'" lines="none" detail="false" routerLinkActive="selected" (click)="onMenuItemClick('Projects')">
              <ion-label class="menu_item">{{"Projects" | translate}}</ion-label>
            </ion-item>

            <ion-item routerDirection="root" [routerLink]="'/tabs/profile'" lines="none" detail="false" routerLinkActive="selected" (click)="onMenuItemClick('MyProfile')">
              <ion-label class="menu_item">{{"MyProfile" | translate}}</ion-label>
            </ion-item>

            <ion-item routerDirection="root" [routerLink]="'/tabs/contactus/'" lines="none" detail="false" routerLinkActive="selected" (click)="onMenuItemClick('ContactUs')">
              <ion-label class="menu_item">{{"ContactUs" | translate}}</ion-label>
            </ion-item>


            <ion-item routerDirection="root" (click)="signOut()" lines="none" detail="false" routerLinkActive="selected" (click)="onMenuItemClick('signOut')" >
              <ion-label class="menu_item">{{"signOut" | translate}}</ion-label>
            </ion-item>

          </ion-menu-toggle>

        </ion-list>

        <img src="../assets/icons/Isolation_Mode.svg" style="width:100%;position: absolute; bottom: 15vh;" class="shake-horizontal">
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
